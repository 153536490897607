<template>
<div class="app-main__inner">
    <div class="row">
        <div class="col-md-12">
            <div class="app-page-title dashboard-title">
                <div class="page-title-wrapper">
                    <div class="page-title-heading">
                        <div class="page-title-icon">
                            <i class="pe-7s-bell icon-gradient bg-happy-itmeo">
                            </i>
                        </div>
                        <div>Notifications
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>
